import React, { Component, useState, useRef } from 'react';
import './PlayerVideo.scss';
import ReactPlayer from 'react-player';

import Modal from 'react-modal';



// const customStyles = {
//     content : {
//         top: "50%",
//         left: "50%",
//         right: "auto",
//         bottom: "auto",
//         transform: "translate(-50%, -50%)"
//     }
//   };



const PlayerExample = (props) => {

  const [played,setPlayed] = useState(0)
  const [seeking,setSeeking] = useState(false)
  const [playing,setPlaying] = useState(false)
  const [openModalFullScreenVideo,setOpenModalFullScreenVideo] = useState(false)
  const [showPlayButtonPreview,setShowPlayButtonPreview] = useState(true)

  const player1 = useRef();
  const player2 = useRef();

  const handleSeekMouseDown = e => {
    setSeeking(true)
  }

  const handleSeekChange = e => {
    setPlayed(parseFloat(e.target.value))
    player1 && player1.current && player1.current.seekTo(parseFloat(e.target.value))
    player2 && player2.current && player2.current.seekTo(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = e => {
    setSeeking(false)
    player1 && player1.current && player1.current.seekTo(parseFloat(e.target.value))
    player2 && player2.current && player2.current.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = state => {
    // if (seeking) {
    //   // this.setState(state)
    //   setPlayed(state.played)
    // }
    setPlayed(state.played)
  }

  const handleClickFullscreen = () => {
    setOpenModalFullScreenVideo(true);
    props.setModeFullScreen(true);
  }

  const handleDoubleClickFullscreen = () => {
    setOpenModalFullScreenVideo(!openModalFullScreenVideo)
    props.setModeFullScreen(!openModalFullScreenVideo)
  }

  const closeModal = () => {
    setOpenModalFullScreenVideo(false)
    props.setModeFullScreen(false)
  }

  const onPlay = () => {
    setShowPlayButtonPreview(false);
    // played === 0 && sendEvent('Video','Played',`${props.url}`);
  }



  return (
    <div className="videoWrapper" onDoubleClick={handleDoubleClickFullscreen}>
        <ReactPlayer 
            ref={player1}
            url={props.url} 
            width="100%"
            height="100%"
            height="25rem"
            loop={false}
            playing={playing}
            muted={true}
            onPlay={() => onPlay()}
            onReady={() => {}}
            onProgress={handleProgress}
            style={{}}
            config={{ file: { 
              attributes: {
                controlsList: 'nofullscreen',  
                playsInline: true,
                scrolling: "no"
              }
          }}}
        />
        <div className="playButtonContainer">
          <div className={`playButton  ${(!playing || played === 0 || played === 1) ? 'visiblePlayPreview' : ''}`} onClick={() => setPlaying(!playing)} />
        </div>
        <div className="containerProgressBar">
            <span className={`playButtonProgressBar ${(playing && played !== 1)? 'pause' : 'play'}`} onClick={() => setPlaying(!playing) } />
            <input
                className="inputSliderVideo"
                type='range' min={0} max={0.999999} step='any'
                value={played}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
            />
            <span className={`fullScreen ${playing? 'pause' : 'play'}`} onClick={handleClickFullscreen} />
        </div>
        <Modal
          isOpen={openModalFullScreenVideo}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // style={customStyles}
          className="videoModal"
          contentLabel="Example Modal"
        >
          <div className='ModalReactPlayer' onDoubleClick={handleDoubleClickFullscreen}>
                <div className={`close closeModalButton`} onClick={closeModal}  />
                <ReactPlayer 
                    ref={player2}
                    url={props.url} 
                    width="90vw"
                    height="90vh"
                    loop={false}
                    playing={playing}
                    // onProgress={this.handleProgress}
                    className="videoplayerWrap"
                    config={{ file: { 
                      attributes: {
                        controlsList: 'nofullscreen',  
                        playsInline: true
                      }
                  }}}
                />
                <div className="playButtonContainer">
                  <div className={`playButton ${(playing && played !== 1)? '' : 'play '} ${(playing === undefined || !playing || played === 0 || played === 1) ? 'visiblePlayPreview' : ''} `} onClick={() => setPlaying(!playing)} />
                </div>
                <div className="containerProgressBar">
                    <span className={`playButtonProgressBar ${playing? 'pause' : 'play'}`} onClick={() => setPlaying(!playing)} />
                    <input
                        className="inputSliderVideo"
                        type='range' min={0} max={0.999999} step='any'
                        value={played}
                        onMouseDown={handleSeekMouseDown}
                        onChange={handleSeekChange}
                        onMouseUp={handleSeekMouseUp}
                    />
                    <span className={`fullScreen ${playing? 'pause' : 'play'}`} onClick={closeModal} />
                </div>
          </div>
        </Modal>
      </div>
  )}


export default PlayerExample;
