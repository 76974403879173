import React, { useMemo } from "react";
import { NOT_VALUED_TEXT } from "./utils.js/constant";
import {TraceabilitySection} from './index.style';

const AgecRule = ({ value, label }) => {
  const displayValue = useMemo(()=> value === "" ? NOT_VALUED_TEXT: value, [value])
    return (
      <TraceabilitySection>
        {label}: {displayValue}
      </TraceabilitySection>
    );
  };

  export default AgecRule;