import { MICROFIBER_TEXT, NOT_VALUED_TEXT } from "./constant";

const getMicrofiberRejectionData = ({ displayed }) => {
  if (displayed === undefined || displayed === null) {
    return `Microfiber Rejection: ${NOT_VALUED_TEXT}`;
  } else if (!displayed) {
    return "";
  } else {
    return MICROFIBER_TEXT;
  }
};

export default getMicrofiberRejectionData;
