export const NOT_VALUED_TEXT = "Non communiqué";
export const MICROFIBER_TEXT =
  "Rejette des microfibres plastiques dans l’environnement lors du lavage";
export const RECYCLED_TEXT =
  "Produit comportant au moins ${percent}% de matières recyclées";
export const RECYCLED_MATERIAL_DATA_MISSING =
  "Rejette des microfibres plastiques dans l’environnement lors du lavage";
export const AGEC_DATA_MISSING =
  "La maison Dior se mobilise actuellement pour recueillir l’ensemble des données soumises à la Loi AGEC. Nous veillons à mettre en œuvre tous les moyens nécessaires pour les fournir dans les plus brefs délais";

export const RECYCLED_MATERIAL = "recycled_material";
export const MICROFIBER_REJECTION = "microfiber_rejection";
export const TRACEABILITY_TYPES_RTW = [
  { weaving: "Tissage" },
  { dye: "Teinture / Impression" },
  { confection: "Confection" },
];
export const TRACEABILITY_TYPES_SHOES = [
  { stitching: "Piquage" },
  { assembly: "Montage" },
  { finishing: "Finition" },
];