import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import './style/index.scss';
import App from './components/App/App';
import store from '../store';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.hydrate(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
document.getElementById('root'));
