import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import "./main.scss";
import NotFound from "../../pages/NotFound";
import i18next from "i18next";
import WishListItems from "../../components/WishListItems/WishListItems";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WishListItemEventCatalog from '../../components/WishListItemsEventCatalog/WishListItemEventCatalog';

export class Wishlist extends Component {

  constructor(props) {
    super(props);
    const language = props.wishlist && props.wishlist.language;
    language && i18next && i18next.changeLanguage(language);
  }
  
  render() {
    const { wishlist, t, ready, isFromEventCatalog} = this.props;
    const lengthProducts =
      wishlist && wishlist.items ? wishlist.items.length : 1;
    const isEventCatalog =  (wishlist.source === "eventCatalog");
    return (
      <>
        {ready ? (
          <div className="wishlist">
            <Header t={t} length={lengthProducts} isSelectionProduct={true} isFromEventCatalog={isFromEventCatalog}/>
            {wishlist && wishlist.items && (
            isEventCatalog ? <WishListItemEventCatalog items={wishlist.items} t={t}/> :  <WishListItems items={wishlist.items} t={t} />
            )}
            
            <Footer wishlist={wishlist} isSelectionProduct={true} isFromEventCatalog={isFromEventCatalog}/>
          </div>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFromEventCatalog : state.wishlist.source === 'eventCatalog',
  ready: state.ready,
  wishlist: state.wishlist || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Wishlist)
);
