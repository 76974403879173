import './DateShared.scss';
import React from "react";

const DateShared = ({ date, t }) => {
  return (
    <div className="DateShared">
        {/* <div className="dateIcon" /> */}
        <div className="date">{date && `${t('wishlist.footer.date.shareone', { date })} | `}  {t("wishlist.footer.mentions")}</div>
    </div>
  );
};

export default DateShared;
