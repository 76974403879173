import styled from 'styled-components';

const StyledContainerTotalPrice = styled.div(() => ({
    display: "inline",
    marginTop: "0.75rem",
    width: "100%"
}))

const StyledContainerDivider = styled.div(() => ({
    backgroundColor: "rgb(240, 240, 240)",
    color: "#000000",
    width: "100%",
    height: "1px",
    marginBottom: "1rem"
}))

const StyledContainerTotal = styled.div(() => ({
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "bold",
    marginTop: "4px"
}))

const StyledContainerPrice = styled.div(() => ({
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: "bold",
}))

const StyledContainer = styled.div(() => ({
    flexDirection: 'row',
    width: "100%",
    justifyContent: 'space-between',
    display: "flex"
}))
export {
    StyledContainerTotalPrice, StyledContainerDivider, StyledContainerTotal, StyledContainerPrice, StyledContainer,
}