import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./main.scss";
import NotFound from "../../pages/NotFound";

import i18next from "i18next";

import { formatCurrency } from "../../../utils/format";
import SliderProduct from "../../components/SliderProduct/SliderProduct";
import { SliderImageVideo } from "../../components/SliderImageVideo/SliderImageVideo";
import ReactModal from "react-modal";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AgecDetails from "./AgecDetails";

ReactModal.setAppElement("#root");

export class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isZoomIn: false,
      currentIndex: 0,
      isFullScreenModalMode: false,
      isOpenModalSelect: false,
    };
    const language = props.wishlist.language;
    i18next.changeLanguage(language);
  }

  setModeFullScreen = (flag) => {
    this.setState({
      isFullScreenModalMode: flag,
    });
  };

  onChangeProduct = () => {
    setTimeout(() => {
      // console.log(this.slider);
      this.setState({ currentIndex: 0 }, () => {
        this.slider && this.slider.slickGoTo(0);
      });
    }, 300);
  };

  sliderGotoCurrentIndex = (slider) => {
    const { currentIndex } = this.state;
    // console.log('currentIndex ' + currentIndex);
    slider && slider.slickGoTo(currentIndex);
  };

  handleOpenModalSlider = (currentIndex) => {
    const { isOpenModalSelect } = this.state;
    this.setState(
      {
        isOpenModalSelect: !isOpenModalSelect,
        currentIndex,
      },
      () => {
        this.sliderGotoCurrentIndex(this.slider);
        this.sliderGotoCurrentIndex(this.sliderModal);
      }
    );
  };

  afterOpenModalSlider = () => {
    this.sliderGotoCurrentIndex(this.sliderModal);
  };

  handleCloseModalSlider = () => {
    this.setState({
      isOpenModalSelect: false,
    });
    // console.log(this.sliderModal);
    this.sliderGotoCurrentIndex(this.sliderModal);
    // console.log("isOpenModalSelect " + this.state.isOpenModalSelect);
  };

  render() {
    const { isZoomIn, isFullScreenModalMode, isOpenModalSelect, currentIndex } =
      this.state;
    const { wishlist, mode, ready, product, item, isFromEventCatalog } =
      this.props;
    const lengthProducts =
      wishlist && wishlist.items ? wishlist.items.length : 1;

    return (
      <>
        {ready ? (
          <div
            className={`product-details ${
              isZoomIn ? "overflow_y_hiddeen" : ""
            }`}
          >
            <div
              className={` ${
                isZoomIn || isFullScreenModalMode || isOpenModalSelect
                  ? "visibility-hidden"
                  : ""
              }`}
            >
              <Header
                t={this.props.t}
                length={lengthProducts}
                onlyLogo={true}
              />
            </div>

            {(lengthProducts > 1 || mode === "multi-product") && (
              <div
                className={`product-details__header ${
                  isZoomIn || isFullScreenModalMode || isOpenModalSelect
                    ? "visibility-hidden"
                    : ""
                }`}
              >
                {mode === "multi-product" && !isFullScreenModalMode && (
                  <Link to={`/${wishlist.id}`} className="arrowBack">
                    <button type="button"></button>
                  </Link>
                )}
                <SliderProduct onChangeProduct={this.onChangeProduct} />
              </div>
            )}
            <SliderImageVideo
              product={product}
              item={item}
              mode={mode}
              handleZoomIn={(val) => this.setState({ isZoomIn: val })}
              wishlist={wishlist}
              handleOpenModalSlider={this.handleOpenModalSlider}
              handleCurentIndexChange={(val) =>
                this.setState({ currentIndex: val })
              }
              currentIndex={currentIndex}
              refSlider={(ref) => (this.slider = ref)}
              modalSliderIsOpen={isOpenModalSelect}
              isFromEventCatalog={isFromEventCatalog}
            />
            <ReactModal
              isOpen={isOpenModalSelect}
              onAfterOpen={this.afterOpenModalSlider}
              onRequestClose={this.handleCloseModalSlider}
              //style={customStyles}
              className="modal-slider"
              contentLabel="Example Modal"
            >
              <SliderImageVideo
                product={product}
                item={item}
                mode={mode}
                handleZoomIn={(val) => this.setState({ isZoomIn: val })}
                wishlist={wishlist}
                refSlider={(ref) => (this.sliderModal = ref)}
                sliderFullSize={true}
                // handleCurentIndexChange={(val) =>
                //   this.setState({ currentIndex: val })
                // }
                currentIndex={currentIndex}
                handleOpenModalSlider={this.handleOpenModalSlider}
              />
              <div
                className={`arrowBack ${isZoomIn ? "disabledArrow" : ""}`}
                onClick={this.handleCloseModalSlider}
              >
                <button type="button"></button>
              </div>
            </ReactModal>

            <div
              className={`product-details__details-section ${
                product && product.images && product.images.length === 1
                  ? "sliderWithOneEl"
                  : ""
              }`}
            >
              <div className="product-details__name">
                {product.titre || item.title}
              </div>
              <div className="product-details__subtitle">
                {product && product.subtitle ? product.subtitle : ""}
              </div>
              {product.new_price && (
                <div className="product-details__price">
                  {formatCurrency(product.new_price, product.new_currency)}
                  {/* <div className="product-details__item-price-comment"> *</div> */}
                </div>
              )}
            </div>
            <div className="product-details__reference-sku">
              {this.props.t("product.fiels.reference")}: {item.sku}
            </div>
            <div className="product-details__description-section">
              <div className="product-details__description">
                {product.descriptif}
              </div>
              {product.traceability?.targeted && (
                <AgecDetails product={product} isFromEventCatalog={isFromEventCatalog}/>
              )}
            </div>
            <Footer
              wishlist={wishlist}
              modeMultiProduct={mode === "multi-product"}
              isFromEventCatalog={isFromEventCatalog}
            />
          </div>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.match.params.productId || state.productId;
  const isFromEventCatalog = state.wishlist.source === "eventCatalog";
  const item =
    state.wishlist && state.wishlist.items
      ? state.wishlist.items.find((item) => item.id === productId)
      : {};

  return {
    ready: state.ready,
    mode: state.mode,
    wishlist: state.wishlist || {},
    item,
    product:
      item.algolia ||
      (item.imageEventCatalog && {
        images: [
          {
            isImageFromEventCatalog: true,
            src: item.imageEventCatalog,
          },
        ],
        titre: item.title,
        new_price: item.price,
        new_currency: item.currency,
        descriptif: item.descrip,
      }) ||
      {},
    isFromEventCatalog,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Product)
);
