import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import '../../style/App.css';
import translations from '../../../utils/i18n';

import ProductDetails from '../../pages/ProductDetails';
import Wishlist from '../../pages/Wishlist';
import NotFound from '../../pages/NotFound';
import Loader from '../../components/Loader';

function OneProduct({ children, mode, productId, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                mode === 'multi-product'
                ? children
                : <Redirect
                    to={{
                        pathname: `/product/${productId}`,
                        state: { from: location }
                    }}/>
            }/>
    );
}


const App = () => {
    const loading = useSelector(state => state.loading);
    const mode = useSelector(state => state.mode);
    const productId = useSelector(state => state.productId);

    const propsState = {loading, mode, productId};
    if(loading) return <Loader/>;
    return (
        <I18nextProvider i18n={translations}>
            <Switch>
                <Route path="/product/:productId" component={ProductDetails}>
                </Route>
                <OneProduct path="/:wishlistId" {...propsState}>
                    <Wishlist/>
                </OneProduct>
                <Route path="/" component={NotFound} />
            </Switch>
        </I18nextProvider>
    );
}

export default App;