import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import './main.scss';

export class NotFound extends Component {

    render() {
        return (
            <div className="not-found">
                <img src='/logo.png' alt="" />
                <div className="not-found__title">{this.props.t('notfound.header.title')}</div>
                <div className="not-found__subtitle">{this.props.t('notfound.header.description')}</div>
            </div>
        );
    }
}

export default withTranslation('common')(NotFound);