import React, { useCallback } from "react";
import AgecRule from "./AgecRule";
import {
  AGEC_DATA_MISSING,
  MICROFIBER_REJECTION,
  NOT_VALUED_TEXT,
  RECYCLED_MATERIAL,
  TRACEABILITY_TYPES_RTW,
  TRACEABILITY_TYPES_SHOES,
} from "./utils.js/constant";
import { DividerLine, TraceabilitySection } from "./index.style";
import getRecycledMaterialData from "./utils.js/getRecycledMaterialData";
import getMicrofiberRejectionData from "./utils.js/getMicrofiberRejectionData";
import { FF_AGEC } from "../../../../config/env";

const AgecDetails = ({ product, isFromEventCatalog }) => {
  const { descriptif, traceability } = product;

  const traceabilityTypesMap = {
    RTW: TRACEABILITY_TYPES_RTW,
    SHOES: TRACEABILITY_TYPES_SHOES,
  };
  const traceabilityTypes = traceabilityTypesMap[traceability.category] || [];

  const displayTraceabilityText = useCallback(
    (type) => {
      const traceabilityType = traceability[type];

      if (traceabilityType === "") {
        return NOT_VALUED_TEXT;
      }

      switch (type) {
        case RECYCLED_MATERIAL:
          return getRecycledMaterialData(traceabilityType);
        case MICROFIBER_REJECTION:
          return getMicrofiberRejectionData(traceabilityType);
        default:
          return "";
      }
    },
    [traceability]
  );

  const showAgec = FF_AGEC;

  return (
    <>
      {descriptif !== "" && <DividerLine />}
      {showAgec && !traceability.has_data && !isFromEventCatalog && (
        <TraceabilitySection>{AGEC_DATA_MISSING}</TraceabilitySection>
      )}
      {showAgec && traceability.has_data && (
        <>
          {traceabilityTypes.map((type) => {
            const [algoliaKey, displayLabel] = Object.entries(type)[0];
            const value = traceability.origin[algoliaKey];
            return (
              <AgecRule key={algoliaKey} label={displayLabel} value={value} />
            );
          })}
          <TraceabilitySection>
            {displayTraceabilityText(RECYCLED_MATERIAL)}
          </TraceabilitySection>
          <TraceabilitySection>
            {displayTraceabilityText(MICROFIBER_REJECTION)}
          </TraceabilitySection>
          <DividerLine />
        </>
      )}
    </>
  );
};

export default AgecDetails;
