import "./WishListItems.scss";

import React from "react";

import { Link } from "react-router-dom";

import { formatCurrency } from "../../../utils/format";
import { getImageItem, getPlaceholderImage } from "../../../utils/image";

const getifCroppingRules = ({ item }) => {
  const { algolia } = item || {};
  const {
    groupe_recherche_3,
    groupe_recherche_2,
    groupe_recherche_1,
  } = algolia || {};
  const { name: name1 } = groupe_recherche_1 || {};
  const { name: name2 } = groupe_recherche_2 || {};
  const { name: name3 } = groupe_recherche_3 || {};

  if (
    ["PANTS/SHORTS", "SHORT"].includes(name1) ||
    ["PANTS/SHORTS", "SHORT"].includes(name2) ||
    ["PANTS/SHORTS", "SHORT"].includes(name3)
  ) {
    return true;
  }
  return false;
};

const WishListItem = ({ items }) => {
  const placeholderImage = getPlaceholderImage();

  return (
    <div
      className={`wishListItems ${
        items && items.length === 1 ? "wishListItem__oneItem" : ""
      }`}
    >
      {items &&
        items.map((item) => {
          const image =
            item.algolia &&
            item.algolia.images &&
            item.algolia.images[0] &&
            item.algolia.images[0];
          return (
            <Link to={`/product/${item.id}`} key={item.id}>
              <div className={`wishListItemContainer --wishListItem`}>
                <div
                  className={`wishListItem-img ${
                    item.algolia && !item.algolia.images ? "imageNotFound" : ""
                  } ${image && image.isLooksImage ? "imgLook" : ""} ${
                    getifCroppingRules({ item }) ? "croppingFromBottom" : ""
                  } `}
                >
                  <img
                    src={getImageItem(image) }
                    onError={(e) => {
                      e.target.src = placeholderImage;
                    }}
                    alt=""
                  />
                </div>
                <div
                  className={`wishListItem-details ${
                    image && image.isLooksImage ? "imgLookText" : ""
                  } `}
                >
                  <div className="wishListItem-name">
                    {(item.algolia && item.algolia.titre) || item.title}
                  </div>
                  <div className="wishListItem-subtitle">
                    {(item.algolia && item.algolia.subtitle) || item.subtitle}
                  </div>
                  {item.algolia && item.algolia.new_price && (
                    <div className="wishListItem-price">
                      {formatCurrency(
                        item.algolia.new_price,
                        item.algolia.new_currency
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default WishListItem;
