import React from "react";
import "./Header.scss";
import { useSelector } from "react-redux";

export const Header = ({ t, length, onlyLogo, isFromEventCatalog }) => {
  const mode = useSelector((state) => state.mode);
  return (
    <div className="header">
      <div className="headerLogo">
        <img src="/logo.png" alt="" width="93" />
      </div>
      {(!onlyLogo || mode !== "multi-product") &&<div className="top-section">
        {mode === "multi-product" ? (
          <div className="wishlist__title">{t("wishlist.header.title")}</div>
        ) : (
          <div className="wishlist__title">
            {t("wishlist.header.share.title")}
          </div>
        )}

        {mode === "multi-product" && !isFromEventCatalog &&(
          <div className="wishlist__text">
            {t("wishlist.header.description", {
              count: `${length}`,
            })}
          </div>
        )}
      </div>}
    </div>
  );
};

export default Header;
