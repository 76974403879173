import styled from "styled-components";


const DividerLine = styled.div(() => ({
    margin: "0.8rem 0rem 1rem 0",
    borderBottom: "solid 0.0625rem #e5e5e5",
}));


const TraceabilitySection = styled.div(props => ({
        fontSize: "0.875rem",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.79",
        color: "#141414",
        ...props.style,
    })
);

export {
    DividerLine, TraceabilitySection 
}
