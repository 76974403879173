import URL from 'url';
import escapeStringRegexp from 'escape-string-regexp';

export const getLocale = () => {
    if(global && global.navigator){
        return global.navigator.languages !== undefined
            ? global.navigator.languages[0]
            : global.navigator.language;
    }
    else if(navigator){
        return navigator.languages !== undefined
            ? navigator.languages[0]
            : navigator.language;
    }
    else {
        return 'fr';
    }
};

export const formatCurrency = (number, currency) => {
    if(!number)
        return '';

    const formatToParts = new Intl.NumberFormat(
        getLocale(),
        {
            style: 'currency',
            currency: ((
                currency === 'RMB'
                ? 'JPY'
                : currency
            ) || 'EUR'),
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }
    )
    .format(number);

    //.formatToParts(number);
    // console.log(number, currency, getLocale())
    // const integer = formatToParts.filter( part => part.type !== 'currency').reduce( (final, part) => final + part.value, '');
    // const symbol = formatToParts.filter( part => part.type === 'currency')[0].value;
    //return integer + symbol;

    return formatToParts;
}

export const formatDate = (date,lng) => {
    try {
        
        let language = lng === 'zh_CN' ? 'zh-cn' : lng;
        language = language === 'zh_TW' ? 'zh-tw' : language;
        const format = new Intl.DateTimeFormat(
            language,
            {
                year: 'numeric',
                month: 'long',
                day: '2-digit'
            }
        );
        return format.format(new Date(date));
    }
    catch (err) {
        // console.log(err);
        const format = new Intl.DateTimeFormat(
            getLocale(),
            {
                year: 'numeric',
                month: 'long',
                day: '2-digit'
            }
        );
        return date && format.format(new Date(date)) || '';
    }
}

export const formatIsoDate = (date) => (
    date.toISOString().substring(0, 10)
);

export const getPathParameters = (url) => {
    var parsed = URL.parse(url, true);

    var params = parsed.pathname.split('/').filter( param => param !== '')

    return params;
}

export const htmlReplace = (html, replacements) => {
    Object.keys(replacements).map(key => {
        const value = replacements[key];
        html = html.split(key).join(value);
    });

    return html;
}