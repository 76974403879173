import React from "react";
import { StyledContainerTotalPrice, StyledContainerDivider, StyledContainerTotal, StyledContainerPrice, StyledContainer } from './TotalPrice.styled';
import { formatCurrency } from '../../../../utils/format';


const TotalPrice = ({ wishlist, t }) => {
    return (
        <StyledContainerTotalPrice>
            {/* <div className="dateIcon" /> */}
            <StyledContainerDivider></StyledContainerDivider>
            <StyledContainer>
                <StyledContainerTotal>
                    {t("wishlist.products.totalAmount")} ({wishlist.totalQuantityItems})
                </StyledContainerTotal>
                <StyledContainerPrice>
                    {formatCurrency(
                        wishlist.totalAmount,
                        wishlist.currency
                    )}
                </StyledContainerPrice>
            </StyledContainer>
        </StyledContainerTotalPrice>
    );
};

export default TotalPrice;