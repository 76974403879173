const errorBase64Image = `data:;base64, RXJyb3IgOiBDYW4gbm90IHNlYWNoIHRoZSBpbWFnZSBhc3NldCBmcm9tIHRoaXMgcmVxdWVzdC4=`;

/**
 * Returns the URL of the placeholder image used when an image is missing or invalid.
 *
 * @return {string} The URL of the placeholder image.
 */
export const getPlaceholderImage = () =>
  "https://www.dior.com/static/fashion/images/myAccount/product-missing-image.png";

/**
 * Returns the image URL from the given input, or a placeholder image URL if the input is falsy or equal to the errorBase64Image constant.
 *
 * @param {string} image - The image URL to process.
 * @return {string} The image URL or the placeholder image URL.
 */
export const getImageEventCatalog = (image) => {
  return image && image !== errorBase64Image ? image : getPlaceholderImage();
};

/**
 * Returns the image item based on the provided image object.
 *
 * @param {Object} image - The image object to retrieve the image item from.
 * @return {string} The image item URL or a placeholder image URL if the image object is falsy.
 */
export const getImageItem = (image) => {
  if (!image) {
    return getPlaceholderImage();
  }

  return image.isLooksImage ? image.raw : `${image.low}&bgColor=240,240,240`;
};
