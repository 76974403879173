import './SliderImageVideo.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Component } from 'react';

import Slider from 'react-slick';
import {
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import VideoPlayer from '../../components/PlayerVideo/PlayerVideo';
import { getImageEventCatalog, getPlaceholderImage } from '../../../utils/image';

const PLACEHOLDER_IMAGE = getPlaceholderImage();

export class SliderImageVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isZoomIn: false,
      currentIndex: 0,
      isFullScreenModalMode: false,
    };
    this.refDotsIndex = [];
  }

  componentDidMount() {
    this.initRefDotIndex();
    this.setState({
      currentIndex: this.props.currentIndex || 0,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.item &&
      prevProps.item &&
      this.props.item.id !== prevProps.item.id
    ) {
      this.initRefDotIndex();
      this.setState({
        isZoomIn: false,
        isFullScreenModalMode: false,
      });
    }
  }


  initRefDotIndex = () => {
    if (
      this.props.product &&
      this.props.product.images &&
      this.props.product.images.length > 0
    ) {
      this.refDotsIndex = [];
      this.props.product.images.forEach((image) => {
        this.refDotsIndex.push(React.createRef());
      });
    }
  };

  setModeFullScreen = (flag) => {
    this.setState({
      isFullScreenModalMode: flag,
    });
    this.props.handleZoomIn(flag);
  };


  setZoomin = ({ val, func }) => {
    this.props.handleZoomIn(val);
    func
      ? this.setState({ isZoomIn: val }, () => func())
      : this.setState({ isZoomIn: val });
  };

  onChangeProduct = () => {
    this.slider && this.slider.slickGoTo(0);
  };

  isSliderCurrentIndex = () => {
    if (this.props.product.images) {
      return this.props.product.images.length === this.state.currentIndex;
    }
    return false;
  };

  resetTransform = (resetTransform) => {
    resetTransform();
    this.setZoomin({ val: false, func: resetTransform });
  };

  zoomInOutImage = (e, zoomIn, resetTransform) => {
    const { isZoomIn } = this.state;
    this.setZoomin({
      val: !isZoomIn,
      func: () => {
        isZoomIn ? resetTransform() : zoomIn(e);
      },
    });
  };

  setZoom = (resetTransform, zoomIn, index) => {
    const { isChanged, currentIndex, isZoomIn } = this.state;
    if (!isZoomIn) return "";
    if (this.state.currentIndex === index) return "my-class-visibility";
    return "my-class-hidden";
  };

  setIsZoomIn = (e, index) => {
    let isZoomIn;
    if (e.scale > 1) {
      isZoomIn = true;
    }
    this.setZoomin({ val: isZoomIn });
  };

  setClassVisibility = (index) => {
    const { isZoomIn } = this.state;
    if (!isZoomIn) return "";
    if (this.state.currentIndex === index) return "my-class-visibility";
    return "my-class-hidden";
  };

  setZIndex = () => {
    const { isZoomIn } = this.state;
    if (!isZoomIn) return "";
    return "z-index-0";
  };

  getOverflow = () => {
    const { isZoomIn } = this.state;
    if (!isZoomIn) return "";
    return "overflow";
  };

  onAfterChange = (index) => {
    this.setState({ currentIndex: index, isChanged: true });
    this.handleCurentIndexChange && this.handleCurentIndexChange(index);
    this.setZoomin({ val: false });
    const indexToScroll = this.refDotsIndex[index + 1] ? index + 1 : index;
    this.refDotsIndex[indexToScroll] &&
      this.refDotsIndex.length > 6 &&
      this.refDotsIndex[indexToScroll].current &&
      this.refDotsIndex[indexToScroll].current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
  };

  getImageSize = (image) => {
    if (image.isImageFromEventCatalog) return getImageEventCatalog(image.src);
    if (this.state.isZoomIn) {
      return image.high;
    }
    return image.raw + "?$Medium_Resolution_Resize$&bgColor=240,240,240"; // "?$Medium_Resolution_Resize$";
  };

  getSettings = () => {
    const { product } = this.props;
    return {
      dots: !this.state.isZoomIn,
      swipe: !this.state.isZoomIn,
      arrows:
        this.state.isFullScreenModalMode || this.props.modalSliderIsOpen
          ? false
          : !this.state.isZoomIn,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: (pagi, i) => {
        const isCurrentIndexVideo = (product.images[pagi] && product.images[pagi].isVideo && "VideoIcon") || "";
        const isCurrentIndexLook = (product.images[pagi] && product.images[pagi].isLooksImage && "LookIcon")  || "";
        const isLastPackShotVideo = (product.images[pagi] && product.images[pagi].isLastPackShotVideo && product.images[pagi + 1] && "isLastPackShotVideo")  || ""; 
        return (
          <button
            ref={this.refDotsIndex && this.refDotsIndex[pagi]}
            className={`slick-dot Bullet ${isCurrentIndexLook} ${isCurrentIndexVideo} ${isLastPackShotVideo}`}
          />
        );
      },
    };
  };

  getPan() {
    return {
      lockAxisY: !this.state.isZoomIn,
      lockAxisX: !this.state.isZoomIn,
      disabled: !this.state.isZoomIn,
    };
  }

  render() {
    const { isZoomIn } = this.state;
    const { sliderFullSize, isFromEventCatalog } = this.props;
    const backgroundProduct = isFromEventCatalog ? 'bgc-event-catalog' :  'bgcGray'
    const doubleClick = {
      step: 100,
      disabled: true,
      animation: true,
      animationType: "easeOut",
      animationTime: 200,
    };

    return (
      <div
        className={`slider-container ${sliderFullSize ? "sliderFullSize" : ""}`}
      >
        <Slider
          ref={this.props.refSlider}
          {...this.getSettings()}
          afterChange={this.onAfterChange}
          className={`slider-section ${this.getOverflow()}`}
        >
           
            {this.props.product.images && this.props.product.images.length > 0 ? (
            this.props.product.images.map((image, index) => 
             image.isVideo ? (
              <div
                key={index}
                className={`videoContainer ${
                  isZoomIn ? "visibility-hidden" : ""
                } ${this.props.sliderFullSize ? "sliderFullSize" : ""}
                ${!this.props.product.images ? 'videoWithNoImage' : ''}`}
              >
                <VideoPlayer
                  url={image.direct + "-0x1080-5000k.mp4"}
                  setModeFullScreen={this.setModeFullScreen}
                  isSliderCurrentIndex={this.isSliderCurrentIndex()}
                />
              </div>
             ) : 
             (
              <div
              key={`images${index}`}
              className={`img-slider ${isZoomIn ? '': backgroundProduct} ${
                image.isLooksImage ? "lookImage" : ""
              }`}
            >
                <TransformWrapper
                  positionY={!this.state.isZoomIn ? 0 : undefined}
                  positionX={!this.state.isZoomIn ? 0 : undefined}
                  onWheel={(e) => {
                    this.setIsZoomIn(e, index);
                  }}
                  onPinching={(e) => {
                    this.setIsZoomIn(e, index);
                  }}
                  doubleClick={doubleClick}
                  pan={this.getPan()}
                  wheel={{}}
                  option={{
                    limitToBounds: true,
                    limitToWrapper: true,
                  }}
                >
                  {({ zoomIn, resetTransform, ...rest }) => {
                    return (
                      <div
                        onDoubleClick={() => {
                          this.resetTransform(resetTransform);
                          this.props.handleOpenModalSlider(
                            this.state.currentIndex
                          );
                        }}
                        className={`relative ${this.setZoom(
                          resetTransform,
                          zoomIn,
                          index
                        )}`}
                      >
                        {this.state.isZoomIn && (
                          <div>
                            <button
                              className={`close ${
                                this.props.mode != "multi-product"
                                  ? "closeWithNotProductHeader"
                                  : ""
                              }`}
                              onClick={(event) =>
                                this.resetTransform(resetTransform)
                              }
                            />
                          </div>
                        )}
                        <TransformComponent>
                          <div>
                            <img
                              className={`${
                                this.state.isZoomIn ? "imageZomming" : ""
                              } ${sliderFullSize ? "sliderFullSize" : ""} ${
                                image && image.isLooksImage ? "imgLook" : ""
                              } `}
                              src={
                                this.getImageSize(image) ||
                                `${PLACEHOLDER_IMAGE}?$Medium_Resolution_Resize$`
                              }
                              onError={(e) => {
                                e.target.src =
                                PLACEHOLDER_IMAGE;
                              }}
                              alt=""
                            />
                          </div>
                        </TransformComponent>
                      </div>
                    );
                  }}
                </TransformWrapper>
              </div>
            ))
          ) : 
            <div className="product-details__img-slider img-slider imageNotFound">
              <img
                src={PLACEHOLDER_IMAGE}
                onError={(e) => {
                  e.target.src = PLACEHOLDER_IMAGE;
                }}
                alt=""
              />
            </div>
           }
        </Slider>
      </div>
    );
  }
}

export default SliderImageVideo;
