import { RECYCLED_MATERIAL_DATA_MISSING, RECYCLED_TEXT } from "./constant";

const getRecycledMaterialData = ({ percent }) => {
  if (percent === 0) {
    return null;
  } else if (percent > 0) {
    return RECYCLED_TEXT.replace("${percent}", percent);
  } else {
    return RECYCLED_MATERIAL_DATA_MISSING;
  }
};

export default getRecycledMaterialData;
