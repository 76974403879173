import React from "react";
import "./ContactInfo.scss";

const ContactInfo = ({ wishlist, t }) => {
  const {
    origin,
    salesAssociateName,
    salesAssociatePhone,
    salesAssociateEmail,
    ownerFirstName,
    genericPhone,
    mainMarketEmail,
  } = wishlist;
  const isCSCAgent = origin === "CSC";
  const contactInfo = {
    name: isCSCAgent ? ownerFirstName : salesAssociateName,
    phone: isCSCAgent ? genericPhone : salesAssociatePhone,
    email: isCSCAgent ? mainMarketEmail : salesAssociateEmail,
  };
  const contactTitle = t("wishlist.footer.sa.title").split("/n");
  const phoneLinkText =
    t("wishlist.footer.sa.callme") ||
    t("wishlist.footer.sa.callme.withoutphone");
  const emailLinkText =
    t("wishlist.footer.sa.emailme") ||
    t("wishlist.footer.sa.emailme.withoutemail");
  return (
    <div className="contact">
      <div className="title">
        {contactTitle[0]} <br /> {contactTitle[1]}
      </div>

      <div className="name">{contactInfo.name}</div>
      <div className="link">
        <a
          href={`tel:${contactInfo.phone || ""}`}
          className={`${phoneLinkText.length > 8 ? "longText" : ""}`}
        >
          <div className="phone" />
          <span className="linkText">{phoneLinkText}</span>
        </a>
        <a
          href={`mailto:${contactInfo.email || ""}`}
          className={`${emailLinkText.length > 8 ? "longText" : ""}`}
        >
          <div className="email" />
          <span className="linkText">{emailLinkText}</span>
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;
