import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./SliderProduct.scss";

const SliderProduct = ({ items, onChangeProduct }) => {
  const [currentItem, setCurrentItem] = useState(0);
  const history = useHistory();

  useEffect(() => {
    // console.log(history.location.pathname);
    const pathname = history && history.location && history.location.pathname;
    const id = pathname && pathname.split("/") && pathname.split("/").pop();
    const index = items && items.findIndex((el) => el.id === id);
    index & setCurrentItem(index);
  }, []);

  const setPathProduct = (value) => {
    const id = items[value] && items[value].id;
    id && history.push("/product/" + id);
    onChangeProduct();
  };

  const handleNext = () => {
    if (currentItem < items.length - 1) {
      const value = currentItem + 1;
      // history.push("/register");
      setCurrentItem(value);
      setPathProduct(value);
    } else {
      const value = 0;
      // history.push("/register");
      setCurrentItem(value);
      setPathProduct(value);
    }
  };

  const handlePrevius = () => {
    if (currentItem > 0) {
      const value = currentItem - 1;
      setCurrentItem(value);
      setPathProduct(value);
    } else {
      const value = items.length - 1;
      setCurrentItem(value);
      setPathProduct(value);
    }
  };

  // console.log(currentItem, items);

  return (
    <div className="sliderProduct">
      <div className="arrowContainer" onClick={handlePrevius}>
        <span>
          {items && items.length > 1 && <div className="arrow left" />}
        </span>
      </div>
      <span>
        <span> {currentItem + 1}/</span>
        <span>{items && items.length ? items.length : 1}</span>
      </span>
      <div className="arrowContainer" onClick={handleNext}>
        <span>
          {items && items.length > 1 && <div className="arrow right" />}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: (state && state.wishlist && state.wishlist.items) || {},
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SliderProduct);
