import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducer';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(){
    const preloadedState = window.PRELOADED_STATE;
    return createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                thunk,
                reduxImmutableStateInvariant()
            )
        )
    );
};

export const store = configureStore();

export default store;