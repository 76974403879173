import React from 'react'
import PropTypes from 'prop-types'

import './main.scss';

const Loader = ({loadingText, showText, showSpinner, color, ...props}) => {
    return (
        <div className="loader" style={{color}}>
            {showSpinner && <div className="loader__spinner" />}
            {showText && <div className="loader__text">{loadingText}</div>}
        </div>
    )
}

Loader.propTypes = {
    loadingText: PropTypes.string,
    showText: PropTypes.bool,
    showSpinner: PropTypes.bool,
    color: PropTypes.string,
};
Loader.defaultProps = {
    loadingText: 'Loading...',
    showText: true,
    showSpinner: true,
    color: '#333',
};


export default Loader
