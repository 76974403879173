import React from "react";
import DateShared from "./DateShared/DateShared";
import { formatDate } from "../../../utils/format";
import { useTranslation } from "react-i18next";
import ContactInfo from "./ContactInfo/ContactInfo";
import TotalPrice from "./TotalPrice/TotalPrice";
import "./Footer.scss";

export const Footer = ({ wishlist, modeMultiProduct, isFromEventCatalog }) => {
  const { t, i18n } = useTranslation();

  const { origin, sharedDate } = wishlist;
  const isNotClient = origin !== "client";
  const lng = i18n.language;

  return (
    <div className="footer">
      {!modeMultiProduct && isFromEventCatalog && (
        <TotalPrice wishlist={wishlist} t={t} />
      )}
      {!modeMultiProduct && isNotClient && (
        <ContactInfo t={t} wishlist={wishlist} />
      )}
      {!isFromEventCatalog && (
        <DateShared date={formatDate(sharedDate || "", lng)} t={t} />
      )}
    </div>
  );
};

export default Footer;
